.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
    position: absolute;
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    background-color: transparent;
    padding: 0;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    overflow: hidden;
}

#external-modal-body {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: var(--main-background-color);
}

/* Modal Container */
.add-sound-modal {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align items to the top */
    position: fixed;
    top: 0; /* Align to the top of the viewport */
    left: 0; /* Align to the left of the viewport */
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1000; /* Base z-index for modals */
    padding-top: 20px; /* Optional: Add some padding at the top */
    box-sizing: border-box; /* Include padding in the element's total width and height */
}

/* Modal Content */
.add-sound-modal .modal-header {
    background: #444;
    padding: 20px;
    border-radius: 10px;
    width: 90%; /* Adjust width as needed */
    max-width: 600px; /* Maximum width for the modal content */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

/* Specific z-index for request-sound-modal-admin */
#request-sound-modal-admin {
    z-index: 1005; /* Higher than the base modal z-index */
}

/* Specific z-index for add-sound-modal */
#add-sound-modal {
    z-index: 1010; /* Highest z-index to appear over other modals */
}