@font-face {
    font-family: 'BagnardSans';
    src: url('../fonts/BagnardSans.otf') format('opentype'); 
    font-weight: normal;
    font-style: normal;
}

button {
    font-family: var(--fontfamilly);
    background-color: var(--main-background-color);
    border: none;
    color: var(--main-text);
    padding: 10px 20px;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
}

.button-primary {
    background-color: var(--main-button-color);
    display: block;
}

.button-primary:hover {
    background-color: var(--main-button-hover-color);
}

.button-primary:disabled {
    background-color: var(--main-button-disable-color);
    cursor: not-allowed;
}

.button-primary-active{
    background-color: var(--main-button-active-color);
    display: block;
}

.button-primary-active:hover{
    background-color: var(--main-button-active-hover-color);
    display: block;
}

.update-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color:  var(--main-button-active-color);
    color: var(--main-text);
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.update-button:hover {
    background-color: var(--main-button-active-color-hover);
}

.text-input{
    font-family: var(--fontfamilly);
    padding: 10px;
    width: 200px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid var(--main-background-color);
    background-color: var(--main-background-color-secondary);
    color: var(--main-text);
}

.text-input::placeholder {
    color: var(--main-text-placeholder);
}

/* Style the select element */
.selector-primary {
    font-family: var(--fontfamilly); /* Match your site's font */
    background-color: var(--main-button-color);
    color:  var(--main-text); /* Text color */
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners */
    padding: 10px 15px; /* Padding for the dropdown */
    font-size: 16px; /* Font size */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s, box-shadow 0.3s; /* Add a hover effect */
}

/* Hover state */
.selector-primary:hover {
    background-color: var(--main-button-hover-color);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); /* Add a shadow */
}

/* Style the dropdown options */
.selector-primary option {
    background-color: var(--main-background-color); /* Match the background color of the select */
    color:  var(--main-text);
    font-family:var(--fontfamilly); /* Font family */
}

.close-button {
    color:  var(--main-button-color);
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    z-index: 1001;
}

.close-button:hover,
.close-button:focus {
    color:  var(--main-button-hover-color);
    text-decoration: none;
    cursor: pointer;
}