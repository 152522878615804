.progress-bar-volume {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0;
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: var(--main-background-color-transparency);
}
.progress-bar-volume:hover,
.progress-bar-volume.touch-hover {
    opacity: 1;
    transition: opacity 300ms;
}

.progress-bar-volume.plus {
    left: 50%;
    right: 0;
}
.progress-bar-volume.minus {
    left: 0;
    right: 50%;
}

.progress-bar-volume.minus:before {
    content: "";
    display: block;
    background-color: var(--main-text);
    height: 2px;
    width: 20px;
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translateX(-50%);
}

.progress-bar-volume.plus:before {
    content: "";
    display: block;
    background-color: var(--main-text);
    height: 2px;
    width: 20px;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translateX(-50%);
}
.progress-bar-volume.plus:after {
    content: "";
    display: block;
    background-color: var(--main-text);
    height: 2px;
    width: 20px;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translateX(-50%) rotate(90deg);
}

.progress-bar-container {
    position: relative;
    width: 100%;
    height: 100px;
    background-color: var(--main-background-color);
    overflow: hidden;
    background-position: center;
    background-size: cover;
    border-radius: 5px;
    border: 2px solid var(--main-border);
}

.ambiance-volume-bar {
    background-color: var(--main-progressbar);
    width: 0%;
    height: 100%;
    transition: width 0.3s ease;
}

.progress-and-credits {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 50%;
    margin: 0 auto;
}

.sound-bar-text,
.sound-bar-licence {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
    text-align: center;
    padding: 5px 10px;
    color: black;
    background-color: var(--main-background-color-transparency);
    user-select: none;
    pointer-events: none;
}

.sound-bar-text {
    top: 10px;
    width: 80%;
}

.sound-bar-licence {
    bottom: 10px;
    width: 40%;
    height: 15px;
    font-size: 10px;
    padding: 1px;
}