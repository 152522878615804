
#progressBar {
    flex-grow: 1;
    height: 4px;
    background-color: var(--main-progressbar);
    margin-right: 10px;
}

#sound-progress {
    height: 100%;
    background-color: var(--main-border);
    width: 0%;
}