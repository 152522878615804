.menu-dropdown {
    position: relative;
}

.menu-button {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
}

.menu-content {
    display: none;
    position: absolute;
    top: 40px;
    right: 0;
    background: var(--main-background-color-secondary);
    padding: 10px;
    z-index: 10;
    transition: all 0.3s;
}
