/*src/css/styles.css*/
@import './colors.css';

/* Basic reset for styling */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@font-face {
    font-family: 'BagnardSans';
    src: url('../fonts/BagnardSans.otf') format('opentype'); 
    font-weight: normal;
    font-style: normal;
}


@import './pages.css';
@import './modal.css';
@import './progressBar.css';
@import './sessionManagement.css';
@import './sounConteiner.css';
@import './preset.css';
@import './credits.css';
@import './buttons.css';
@import './dropDownMenu.css';
@import './editSounds.css';
@import './ambianceControls.css';